import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { UpdateMemberProfileRequestModel } from 'app/modules/user/models/update-member-profile-request.model';
import { BaseService } from '@core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(
    private _http: HttpClient,
    private baseService: BaseService
  ) { }

  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}/users`);
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  updateMemberProfile(model: UpdateMemberProfileRequestModel): Observable<any> {
    return this.baseService.put<any>(environment.apiUrl + 'Dashboard/UpdateMemberProfile', model)
  }

  getMemberProfileData(): Observable<any> {
    return this.baseService.get<any>(environment.apiUrl + 'Dashboard/GetMemberProfileData')
  }
}
