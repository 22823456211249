import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpOptions } from '../models/http-options.interface';

@Injectable({providedIn: 'root'})
export class BaseService {
  
  constructor(private http: HttpClient) {
  }

  get<T>(url: string, options: HttpOptions = {}): Observable<T> {
    return this.http.get<T>(url, options);
  }

  post<T>(url: string, body: T, options: HttpOptions = {}): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  put<T>(url: string, body: T, options: HttpOptions = {}): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

}